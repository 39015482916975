<template>
  <div class="page-container" v-if="loading">
    <div
      class="container-main-block"
      :style="tab === 'users' ? `height: calc(100vh - 64px);` : 'height: 100vh'"
    >
      <div class="main-block-title pl-16 pr-16">
        <div class="block-title-text">
          <div class="title-text-name">
            {{ item.title }}
          </div>
          <div class="title-text-card" v-if="item.isSmart">
            <v-icon color="var(--app-blue)" size="20">mdi-sync-circle</v-icon>
            <span> smart-группа </span>
          </div>
        </div>
        <div class="block-title-buttons">
          <v-btn
            depressed
            class="button-white-red"
            @click="dialogDelete = true"
          >
            Удалить группу
          </v-btn>
          <v-btn
            depressed
            class="button-white-blue ml-4"
            @click="openDialogChange"
          >
            Изменить название
          </v-btn>
        </div>
      </div>
      <v-tabs
        class="pl-16 pr-16"
        v-model="tab"
        background-color="var(--app-grey-1)"
        color="var(--app-blue)"
      >
        <v-tabs-slider color="var(--app-blue)"></v-tabs-slider>
        <v-tab :href="'#users'">
          <v-icon class="mr-2" size="18">mdi-account-multiple</v-icon>
          <div class="tab-text">Пользователи</div>
        </v-tab>
        <v-tab disabled :href="'#content'">
          <!-- @click="getValuesContent" -->
          <v-icon class="mr-2" size="18">mdi-eye</v-icon>
          <div class="tab-text">Доступные курсы</div>
        </v-tab>
        <v-tab disabled :href="'#homework'">
          <v-icon class="mr-2" size="18">mdi-school</v-icon>
          <div class="tab-text">Назначенное обучение</div>
        </v-tab>
        <v-tab v-if="item.isSmart" :href="'#tags'">
          <v-icon class="mr-2" size="18">mdi-settings</v-icon>
          <div class="tab-text">Настройки smart-группы</div>
        </v-tab>
      </v-tabs>
      <v-divider style="margin: 0 64px"></v-divider>
      <div class="main-block-items">
        <v-tabs-items v-model="tab" >
          <v-tab-item value="users">
            <GroupUsers
              :key="item.users.length"
              ref="groupUsers"
              :item="item"
              @selectedUsers="(items) => (selectedUsers = items)"
            />
          </v-tab-item>
          <v-tab-item value="content">
            <!-- <LessonContent :item="item" /> -->
          </v-tab-item>
          <v-tab-item value="homework"> <GroupAppointmentEducation :item="item"/> </v-tab-item>
            <v-tab-item value="tags"> <GroupTags :item="item"></GroupTags> </v-tab-item>
        </v-tabs-items>
      </div>
    </div>
    <div class="container-bottom-block" v-if="tab === 'users'">
      <v-btn
        v-if="item.isSmart"
        depressed
        :disabled="selectedUsers.length === 0"
        class="button-white-red"
      >
        Добавить в исключение
      </v-btn>
      <v-btn
        v-else
        depressed
        :disabled="selectedUsers.length === 0"
        @click="deleteUsers"
        :loading="send"
        class="button-white-red"
      >
        Удалить пользователей из группы
      </v-btn>
      <div class="bottom-block-text">Выбрано: {{ selectedUsers.length }}</div>
    </div>

    <DialogConfirm
      v-model="dialogDelete"
      titleText="Удаление группы"
      :mainText="`Вы уверены что хотите группу ${item ? `${item.title}` : ''}?`"
      @click:delete="deleteItem"
    >
    </DialogConfirm>
    <Dialog
      v-model="dialogChangeName"
      refForm="nameForm"
      titleText="Изменение названия группы"
      :mainItems="[
        {
          type: 'text',
          label: 'Название',
          value: this.newItem.title,
          vmodel: (e) => (this.newItem.title = e),
          valid: true,
        },
      ]"
      @click:success="changeName"
      :buttonsFooter="{
        success: {
          title: 'Сохранить',
          loading: true,
        },
      }"
    >
    </Dialog>
  </div>
</template>
<script>
import DialogConfirm from "@/components/main/dialog/DialogConfirm";
import Dialog from "@/components/main/dialog/Dialog";
import GroupUsers from "@/views/Groups/GroupUsers";
import GroupTags from "@/views/Groups/GroupTags";
import GroupAppointmentEducation from './GroupAppointmentEducation.vue';

// import LessonContent from "@/views/Lessons/LessonContent";

export default {
  components: {
    DialogConfirm,
    Dialog,
    GroupUsers,
    GroupTags,
    GroupAppointmentEducation,
    // LessonInformation,
    // LessonContent,
  },
  data: () => ({
    item: {},
    selectedUsers: [],
    loading: false,
    tab: '',
    dialogDelete: false,
    dialogChangeName: false,
    send: false,
    newItem: { title: "" },
  }),
  methods: {
    openDialogChange() {
      this.newItem.title = this.item.title;
      this.dialogChangeName = true;
    },
    changeName(method) {
      this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/group",
          {
            title: this.newItem.title,
            id: this.item.id,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(async () => {
          await this.getItem();
          method();
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    deleteUsers() {
      this.send = true;
      const newArr = this.selectedUsers.map((i) => {
        return i.id;
      });
       this.$axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/group/users",
          {
            id: this.item.id,
            usersIds: newArr,
            toAdd: false,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.send = false;
          this.getItem();
        })
        .catch((err) => {
          this.send = false;
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    async getItem() {
      await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then((res) => {
          this.item = res.data;
          if (this.item.users && this.item.users.length > 0) {
            this.item.users = this.item.users.map((element) => {
              element.fullName =
                (element.lastName ? element.lastName + " " : "") +
                (element.firstName ? element.firstName + " " : "") +
                (element.middleName ? element.middleName : "");
              return { ...element };
            });
          }
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getAllValues() {
      Promise.all([this.getItem()]).then(() => {
        this.loading = true;
      });
    },

    deleteItem() {
      this.$axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/group", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            id: this.$route.params.id,
          },
        })
        .then(() => {
          this.$router.push({ name: "Groups" });
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    getValuesContent() {
      if (this.$refs.courseContent) {
        this.$refs.courseContent.getItems();
      }
    },
  },
  created() {
    this.getAllValues();
  },
};
</script>
<style lang="scss" scoped>
.page-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  .container-main-block {
    display: flex;
    flex-direction: column;
    .main-block-title {
      margin-top: 40px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .block-title-text {
        display: flex;
        align-items: center;
        .title-text-name {
          font-size: 28px;
          font-weight: 600;
          color: var(--app-black);
        }
        .title-text-card {
          padding: 8px 16px;
          border-radius: 18px;
          background: var(--app-blue-light);
          display: flex;
          align-items: center;
          margin-left: 24px;
          span {
            text-transform: uppercase;
            margin-left: 4px;
            font-size: 14px;
            font-weight: 500;
            color: var(--app-blue);
          }
        }
      }
      .block-title-buttons {
        display: flex;
      }
    }
    .main-block-items {
      height: 100%;
      overflow: auto;
    }
  }
  .container-bottom-block {
    height: 64px;
    background: var(--app-grey-2);
    display: flex;
    align-items: center;
    padding: 0px 64px;
    .bottom-block-text {
      margin-left: 32px;
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
}
</style>