<template>
  <div class="group-tags-block">
    <div class="block-title">
      <div>
        <v-btn
          @click="openDialog('значению')"
          depressed
          class="button-white-blue mr-4"
        >
          <v-icon small>mdi-format-list-bulleted</v-icon
          ><span>Добавить тег по значению</span>
        </v-btn>
        <v-btn @click="openDialog('дате')" depressed class="button-white-blue">
          <v-icon small>mdi-calendar-range</v-icon
          ><span>Добавить тег по дате</span>
        </v-btn>
      </div>
      <div>
        <!-- <v-btn disabled depressed class="button-white-black" v-if="item.isSmart">
          Исключения smart-группы
        </v-btn> -->
      </div>
    </div>
    <div class="block-items">
      <Table
        :items="tagsGroup"
        :headers="headers"
        @click:row="dialogTagEditMeth"
        :noClickSlots="['delete']"
        headerFixed
        ><template v-slot:[`item.tagType`]="{ item }">
          {{ item.tagType == "value" ? "Значение" : "Дата" }}
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <div @click="deleteTagInGroup(item)"><v-icon>mdi-close</v-icon></div>
        </template>
        <template v-slot:[`item.values`]="{ item }">
          <template v-if="item.values">
            <div style="max-width: 500px">
              <div v-if="item.values.length == 0" style="font-weight: bold">
                {{ "—" }}
              </div>
              <div v-for="(itemValue, id) in item.values" :key="id">
                {{ itemValue.name }}
                <div v-if="itemValue.dateType">
                  {{
                    itemValue.dateType == "period"
                      ? `Период: от ${itemValue.from} ${convertToRussianDate(
                          itemValue.datePeriodFrom,
                          itemValue.from
                        )} до ${itemValue.to} ${convertToRussianDate(
                          itemValue.datePeriodto,
                          itemValue.to
                        )}`
                      : `По дате: от ${momentDate(
                          itemValue.from,
                          "from"
                        )} до ${momentDate(itemValue.to, "to")}`
                  }}
                </div>
              </div>
            </div>
          </template></template
        >
      </Table>
    </div>
    <Dialog
      v-model="dialogTagEdit"
      :titleText="`Изменение тега по по ${editString}`"
      :refForm="'formTag'"
      :width="700"
      @click:success="sendEditTagClipToGroup"
    >
      <template v-slot:blockMain>
        <div>
          <SelectInput
            label="Выберите тег"
            :items="tagsGroup"
            valid
            itemValue="tagId"
            itemText="name"
            type="number"
            :hideDetails="true"
            v-model="selectValueEdit"
            @change="
              dialogTagEditMeth(
                tagsGroup.find(
                  (tagsGroup) => tagsGroup.tagId == selectValueEdit
                )
              )
            "
            clearable
          ></SelectInput>
        </div>
        <div v-if="selectValueEdit.tagType == 'date'">
          <div class="mt-3">
            <SelectInput
              v-show="selectValueEdit"
              :items="typesChoiseDateInput"
              label="Тип"
              itemText="name"
              v-model="typeChoise"
              itemValue="value"
              :clearable="false"
              :hideDetails="true"
            ></SelectInput>
            <div
              v-if="typeChoise === 'date'"
              class="d-flex align-center justify-content-center mt-3"
            >
              <div class="pr-5">От</div>
              <DateInput
                label="Дата"
                :hideDetails="true"
                clearable
                v-model="from"
              ></DateInput>
              <div class="pr-5 pl-5">До</div>
              <DateInput
                label="Дата"
                :hideDetails="true"
                clearable
                v-model="to"
              ></DateInput>
            </div>
            <div
              v-if="typeChoise === 'period'"
              class="d-flex align-center justify-content-center mt-3"
            >
              <!-- <v-form ref="refPeriod"> -->
              <div class="mr-3">От</div>
              <SelectInput
                :clearable="false"
                :items="typesSelectedDateInput"
                label="Период"
                itemText="name"
                itemValue="value"
                v-model="datePeriodFrom"
                :hideDetails="true"
              ></SelectInput>
              <TextInput
                class="ml-4"
                label="Кол-во"
                type="number"
                v-model="from"
                :hideDetails="true"
              ></TextInput>

              <div class="ml-3 mr-3">До</div>

              <SelectInput
                :clearable="false"
                :items="typesSelectedDateInput"
                label="Период"
                v-model="datePeriodTo"
                :hideDetails="true"
                itemText="name"
                itemValue="value"
              ></SelectInput>
              <TextInput
                class="ml-4"
                label="Кол-во"
                type="number"
                :hideDetails="true"
                v-model="to"
              ></TextInput
              ><!-- </v-form> -->
            </div>
          </div>
        </div>
        <div v-else>
          <Table
            :items="selectValueEdit.arrTagsCheckBox"
            :headers="headersDialog"
            headerHidden
          >
            <template v-slot:[`item.checkBox`]="{ item }">
              <div @click="selectItems(item)">
                <CheckBox v-model="item.checkBox" color="#0078FF" />
              </div>
            </template>
          </Table>
        </div>
      </template>
      <template v-slot:buttonDelete><div></div></template>
    </Dialog>
    <Dialog
      v-model="dialogTag"
      :titleText="`Добавление тега по ${editString}`"
      ref="dialogAppointment"
      :refForm="'formAppointment'"
      @click:success="sendTagClipToGroup"
      :mainItems="[
        {
          slotName: 'select',
        },
        { slotName: 'table' },
      ]"
    >
      <template v-slot:select>
        <SelectInput
          label="Выберите тег"
          :items="editString == 'дате' ? tagsDate : tagsValues"
          valid
          itemValue="tagId"
          itemText="name"
          type="number"
          :hideDetails="true"
          v-model="selectValue"
          clearable
        ></SelectInput>
      </template>
      <template v-slot:radio
        ><RadioButtons :items="items" v-model="radioValue" /></template
      ><template v-slot:table>
        <!--  <div class="mt-8" style="height: 300px">
          <Table headerHidden>
            <template v-slot:[`item.checkBox`]="{ item }">
              <div>
                <CheckBox v-model="item.checkBox" color="#0078FF" />
              </div>
            </template>
          </Table></div
      > --></template
      >
      <template v-slot:buttonDelete><div></div></template>
    </Dialog>
    <DialogConfirm ref="confirmDeleteTag" />
  </div>
</template>

<script>
import axios from "axios";
import SelectInput from "../../components/main/inputs/SelectInput.vue";
import CheckBox from "../../components/main/inputs/CheckBox.vue";
import DateInput from "../../components/main/inputs/DateInput.vue";
import DialogConfirm from "../../components/main/dialog/DialogConfirm.vue";
export default {
  components: { SelectInput, CheckBox, DateInput, DialogConfirm },
  props: {
    item: {
      type: Object,
      default() {
        return {
          isSmart: false,
        };
      },
    },
  },
  data: () => ({
    from: 0,
    to: 0,
    datePeriodFrom: null,
    datePeriodTo: null,
    dialogTagEdit: false,
    selectValue: null,
    selectValueEdit: null,
    unlinkedTags: [],
    tagsGroup: [],
    tagsDate: [],
    tagsValues: [],
    targetIds: [],
    typeChoise: null,
    arrTagsCheckBox: [],
    selectedItem: {
      values: [],
    },
    editString: "",
    typesChoiseDateInput: [
      {
        name: "Дата",
        value: "date",
      },
      {
        name: "Период",
        value: "period",
      },
    ],
    typesSelectedDateInput: [
      {
        name: "День",
        value: "day",
        valueMs: 86400000,
      },
      {
        name: "Неделя",
        value: "week",
        valueMs: 604800000,
      },
      {
        name: "Месяц",
        value: "month",
        valueMs: 2592000000,
      },

      {
        name: "Год",
        value: "year",
        valueMs: 31536000000,
      },
    ],
    dialogTag: false,
    headersDialog: [
      {
        text: "",
        align: "flex-left",
        sortable: false,
        value: "checkBox",
      },
      {
        text: "",
        align: "flex-left",
        sortable: false,
        value: "name",
      },
    ],
    headers: [
      {
        text: "Тип",
        align: "flex-left",
        sortable: false,
        value: "tagType",
      },
      {
        text: "Название",
        align: "flex-left",
        sortable: false,
        value: "name",
      },
      {
        text: "Выбранные значения тега",
        align: "flex-left",
        sortable: false,
        value: "values",
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "delete",
      },
    ],
  }),
  computed: {},
  mounted() {
    this.tagsGroupGet();
    this.tagsAllGet();
  },
  methods: {
    momentDate(date, checkString) {
      if (date) {
        return this.$moment(date).format("DD.MM.YYYY");
      } else {
        if (checkString == "from") return "с текущей даты";
        else if (checkString == "to") return "по текущую дату";
      }
    },
    convertToRussianDate(string, value) {
      if (string == "month") {
        return this.declinationOfWords(value, ["месяца", "месяцев", "месяцев"]);
      } else if (string == "day") {
        return this.declinationOfWords(value, ["дня", "дней", "дней"]);
      } else if (string == "year") {
        return this.declinationOfWords(value, ["года", "лет", "лет"]);
      } else if (string == "week") {
        return this.declinationOfWords(value, ["недели", "недель", "недель"]);
      }
    },
    deleteTagInGroup(item) {
      axios
        .delete(this.$store.getters.apiAdminPanelV4 + "/tag/group", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            tagType: item.tagType,
            groupId: this.$route.params.id,
            tagId: item.tagId,
          },
        })
        .then(() => {
          this.tagsGroupGet();
          this.tagsAllGet();
        })
        .catch((err) => {
          this.snackbar.isOpen = true;
          this.snackbar.text = err.response.data || err.stack;
        });
    },
    selectItems(item) {
      if (item.checkBox) {
        this.targetIds.push(item.value);
      } else {
        this.targetIds = this.targetIds.filter((v) => v != item.value);
      }
    },
    addTagValue() {
      this.selectValueEdit.values.push({
        idNew: (~~(Math.random() * 1e8)).toString(16),
        value: "",
      });
    },
    dialogTagEditMeth(item) {
      if (item.tagType == "date") {
        this.editString = "дате";
        if (item.dateType == "period") {
          this.typeChoise = "period";
          this.datePeriodFrom = item.datePeriodFrom;
          this.datePeriodTo = item.datePeriodto;
          this.from = item.from;
          this.to = item.to;
        } else if (item.dateType == "date") {
          this.typeChoise = "date";
          this.from = item.from;
          this.to = item.to;
        }
      } else {
        this.checkBoxMethAdd(item);
        this.editString = "значению";
      }
      this.selectValueEdit = item;
      this.selectValueEdit.arrTagsCheckBox = this.arrTagsCheckBox;
      this.dialogTagEdit = true;
    },
    checkBoxMethAdd(item) {
      let arrFalseCheck = [];
      let arrTrueCheck = [];
      arrFalseCheck = item.otherValues.map((v) => {
        return {
          name: v.name,
          value: v.value,
          checkBox: false,
        };
      });
      arrTrueCheck = item.values.map((v) => {
        return {
          name: v.name,
          value: v.value,
          checkBox: true,
        };
      });
      for (let i = 0; i < arrTrueCheck.length; i++) {
        this.targetIds.push(arrTrueCheck[i].value);
      }
      this.arrTagsCheckBox = arrTrueCheck.concat(arrFalseCheck);
    },
    sendEditTagClipToGroup(method) {
      let sendEditTagsValues = [];
      if (this.selectValueEdit.tagType == "value") {
        sendEditTagsValues = this.selectValueEdit.arrTagsCheckBox
          .map((v) => {
            if (v.checkBox) {
              return {
                tagType: this.selectValueEdit.tagType,
                tagId: this.selectValueEdit.tagId,
                value: v.value,
              };
            }
          })
          .filter((v) => v);
      } else {
        if (this.typeChoise == "period") {
          sendEditTagsValues = [
            {
              tagId: this.selectValueEdit.tagId,
              tagType: "date",
              dateType: "period",
              datePeriodFrom: this.datePeriodFrom,
              datePeriodTo: this.datePeriodTo,
              from: this.from? this.from:0,
              to: this.to? this.to:0,
            },
          ];
        } else {
          sendEditTagsValues = [
            {
              tagId: this.selectValueEdit.tagId,
              tagType: "date",
              dateType: "date",
              from: this.from,
              to: this.to,
            },
          ];
        }
      }
      if (sendEditTagsValues.length == 0) {
        this.deleteTagInGroup(this.selectValueEdit);
        method();
      } else {
        axios
          .post(
            this.$store.getters.apiAdminPanelV4 + "/tag/group",
            {
              groupId: this.$route.params.id,
              tag: sendEditTagsValues,
            },
            {
              headers: {
                authorization: this.$store.getters.adminPanelHeaderAuth,
              },
            }
          )
          .then(() => {
            this.tagsGroupGet();
            this.tagsAllGet();
            method();
          })
          .catch((err) => {
            this.$store.commit("errorSet", true);
            this.$store.commit("errorTextSet", err);
          });
      }
    },
    sendTagClipToGroup(method) {
      const sendTag = this.unlinkedTags.filter(
        (v) => v.tagId == this.selectValue
      );
      axios
        .post(
          this.$store.getters.apiAdminPanelV4 + "/tag/group",
          {
            groupId: this.$route.params.id,
            tag: sendTag,
          },
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
          }
        )
        .then(() => {
          this.tagsAllGet();
          this.tagsGroupGet();

          method();
        });
    },
    tagsGroupGet() {
      axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group/linked/tagList", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            groupId: this.$route.params.id,
          },
        })
        .then((res) => {
          this.tagsGroup = res.data;
          let values = [];
          let tagsGroupNew = [];
          for (let i = 0; i < this.tagsGroup.length; i++) {
            if (this.tagsGroup[i].tagType != "date") {
              if (!tagsGroupNew.includes(this.tagsGroup[i].tagId)) {
                tagsGroupNew.push(this.tagsGroup[i].tagId, {
                  otherValues: this.tagsGroup[i].otherValues,
                  name: this.tagsGroup[i].name,
                  tagType: this.tagsGroup[i].tagType,
                  tagId: this.tagsGroup[i].tagId,
                  values: [],
                });
              }
              values.push({
                tagId: this.tagsGroup[i].tagId,
                values: this.tagsGroup[i].values,
              });
            } else if (this.tagsGroup[i].tagType == "date") {
              tagsGroupNew.push(this.tagsGroup[i].tagId, {
                datePeriodFrom: this.tagsGroup[i].datePeriodFrom,
                datePeriodto: this.tagsGroup[i].datePeriodto,
                dateType: this.tagsGroup[i].dateType,
                from: this.tagsGroup[i].from,
                name: this.tagsGroup[i].name,
                tagType: this.tagsGroup[i].tagType,
                tagId: this.tagsGroup[i].tagId,
                to: this.tagsGroup[i].to,
                values: [
                  {
                    dateType: this.tagsGroup[i].dateType,
                    datePeriodFrom: this.tagsGroup[i].datePeriodFrom,
                    datePeriodto: this.tagsGroup[i].datePeriodto,
                    from: this.tagsGroup[i].from,
                    to: this.tagsGroup[i].to,
                  },
                ],
              });
            }
          }
          for (let i = 0; i < values.length; i++) {
            for (let j = 0; j < tagsGroupNew.length; j++) {
              if (typeof tagsGroupNew[j] != "string") {
                if (values[i].tagId == tagsGroupNew[j].tagId) {
                  if (values[i].values.length != 0) {
                    tagsGroupNew[j].values.push(values[i].values[0]);
                  }
                }
              }
            }
          }
          tagsGroupNew = tagsGroupNew.filter((v) => typeof v != "string");
          this.tagsGroup = Object.assign(tagsGroupNew);
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    tagsAllGet() {
      axios
        .get(this.$store.getters.apiAdminPanelV4 + "/group/unlinked/tagList", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
          params: {
            groupId: this.$route.params.id,
          },
        })
        .then((res) => {
          this.unlinkedTags = res.data;
          const lengthTags = this.unlinkedTags.length;
          for (let i = 0; i < lengthTags; i++) {
            if (res.data[i].tagType == "date") {
              this.tagsDate.push(res.data[i]);
            } else if (res.data[i].tagType == "value") {
              this.tagsValues.push(res.data[i]);
            }
          }
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
    openDialog(string) {
      this.dialogTag = true;
      this.editString = string;
    },
    sendTag() {},
  },
};
</script>

<style lang="scss" scoped>
.group-tags-block {
  height: 100%;
  padding: 32px 64px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .block-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  .block-items {
    height: calc(100vh - 290px);
  }
}
</style>