<template>
  <div style="display: flex" class="mb-5">
    <div
      v-for="(radio, id) in items"
      :key="id"
      style="margin-right: 18px"
      @click="radioValueMeth(radio)"
    >
      <input type="radio" :checked="value == radio.value" />
      <label>{{ radio.name }}</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {},
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    radioValueMeth(item) {
      this.$emit("input", item.value);
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
label {
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -0.18px;
  text-align: left;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 21px;
  height: 21px;
  border: 1px solid var(--app-separators);
  border-radius: 100%;
  background: var(--app-white);
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: "";
  width: 13px;
  height: 13px;
  background: var(--app-blue);
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
</style>