<template>
  <div class="group-users-block" >
    <div class="block-title">
      <div style="width: 300px">
        <SearchInput :disabled="item.users.length == 0" class="mr-4" v-model="search" label="Поиск пользователя" />
      </div>
      <div>
        <v-btn disabled depressed class="button-white-black" v-if="item.isSmart">
          Исключения smart-группы
        </v-btn>
      </div>
    </div>
    <div class="block-items" >
      <Table
        :items="search && search.length != 0 ? searchingItems :  items"
        :headers="usersHeaders"
        headerFixed
        :noClickSlots="['checkbox']"
      >
        <template v-slot:[`header.checkbox`]>
          <CheckBox
            v-model="allChecked"
            :main="true"
            @change="clickHeaderCheckBox"
            :background="true"
            color="var(--app-blue)"
          />
        </template>
        <template v-slot:[`item.checkbox`]="{ item }">
          <CheckBox
            v-model="item.checkbox"
            @change="checkboxMeth($event, item)"
             color="var(--app-blue)"
          />
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <template v-if="item.fullName">
            {{ item.fullName }}
          </template>
          <template v-else-if="item.email">
            {{ item.email }}
          </template>
          <template v-else-if="item.phoneNumber">
            {{ item.phoneNumber }}
          </template>
        </template>
      </Table>
     
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/main/inputs/SearchInput";
import CheckBox from "@/components/main/inputs/CheckBox.vue";
export default {
  components: {
    SearchInput, CheckBox
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          isSmart: false,
        };
      },
    },
  },
  data: () => ({
    search: "",
    allChecked: false,
    usersHeaders: [
      {
        align: "flex-left",
        sortable: false,
        value: "checkbox",
      },
      {
        text: "ФИО",
        align: "flex-left",
        sortable: false,
        value: "fullName",
      },
    ],
    selectedItems:[],
    items: [],
  }),
  computed: {
    searchingItems() {
      return this.items.filter((i) =>
        i.fullName.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  created(){
    if(this.item.users){
      this.items = this.item.users.map(i=> {return { ...i, checkbox: false }})
    }
  },
  methods:{
    clickHeaderCheckBox(bool){
        this.selectedItems = [];
      this.items.forEach((element) => {
        element.checkbox = bool;

        if (bool) {
          this.selectedItems.push({ ...element, checkbox: true });
        }
      });
      this.$emit('selectedUsers',this.selectedItems)
    },
     checkboxMeth(bool, item) {
      if (bool) {
        this.selectedItems.push({ ...item, checkbox: true });
      } else {
        this.selectedItems = this.selectedItems.filter((v) => v.id != item.id);
      }
      this.selectedItems.length == this.items.length
        ? (this.allChecked = true)
        : (this.allChecked = false);
      this.$emit('selectedUsers',this.selectedItems)
    },
  }
};
</script>

<style lang="scss" scoped>
.group-users-block {
  height:  calc(100vh - 216px);
  padding: 32px 64px 0px 64px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .block-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
   .block-items {
    height:  calc(100vh - 320px);
  }
}
</style>