<template>
  <div style="padding: 32px 64px">
    <div class="block-buttons">
      <v-btn
        @click="openDialog('курс')"
        class="button-white-blue"
        style="margin-right: 19px"
        depressed
      >
        <span>Назначить курс</span>
      </v-btn>
      <v-btn
        @click="openDialog('экзамен')"
        class="button-white-blue"
        style="margin-right: 19px"
        depressed
      >
        <span>Назначить экзамен</span>
      </v-btn>
    </div>
    <div class="setting-block-statistics">
      <div class="statistics-block">
        <div class="statistics-block-title">Всего курсов</div>
        <div class="statistics-block-value">
          <!-- {{ users.length }} -->
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Всего экзаменов</div>
        <div class="statistics-block-value">
          <!-- {{ groups.length }} -->
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Прохождение</div>
        <div class="statistics-block-value">
          <!-- {{ endCourse(!group.isSmart ? users : groups) }} % -->
        </div>
      </div>
      <div class="statistics-block">
        <div class="statistics-block-title">Средний результат</div>
        <div class="statistics-block-value">
          <!-- {{ averageMeth(!group.isSmart ? users : groups) }} % -->
        </div>
      </div>
    </div>
    <div style="height: calc(100vh - 285px); margin-top: 40px">
      <Table :headers="headers" :items="coursesTests" headerFixed> </Table>
    </div>
    <Dialog
      v-model="dialogRadio"
      :titleText="`Назначение ${editString}а группе`"
      ref="dialogAppointment"
      :refForm="'formAppointment'"
      @click:success="sendAppointmentData"
      :mainItems="[
        { slotName: 'radio' },
        {
          slotName: 'date',
        },
        { slotName: 'table' },
      ]"
    >
      <template v-slot:date>
        <DateInput
          v-if="radioValue == 0"
          label="Дата"
          valid
          v-model="dateMs"
          :hideDetails="true"
          clearable
        ></DateInput>
        <TextInput
          v-if="radioValue == 1"
          label="Дней"
          valid
          type="number"
          :hideDetails="true"
          clearable
        ></TextInput>
      </template>
      <template v-slot:radio
        ><RadioButtons :items="radioArr" v-model="radioValue" /></template
      ><template v-slot:table>
        <div class="mt-8" style="height: 300px">
          <Table
            :items="courses"
            :headers="headersDialog"
            headerHidden
          >
            <template v-slot:[`item.checkBox`]="{ item }">
              <div @click="selectItems(item)">
                <CheckBox v-model="item.checkBox" color="#0078FF" />
              </div>
            </template>

          </Table>
        </div></template
      >
      <template v-slot:buttonDelete>Выбрано: {{ targetIds.length }}</template>
    </Dialog>
  </div>
</template>

<script>
import CheckBox from '../../components/main/inputs/CheckBox.vue';
import DateInput from "../../components/main/inputs/DateInput.vue";
import RadioButtons from "../../components/main/inputs/RadioButtons.vue";
import TextInput from "../../components/main/inputs/TextInput.vue";
import Table from "../../components/main/table/Table.vue";
export default {
  components: { Table, RadioButtons, DateInput, TextInput, CheckBox },
  props: {
    item: { type: Object },
  },
  data: () => ({
    radioValue: 0,
    search: "",
    dateMs:null,
    dialogRadio: false,
    editString: "курс",
     radioArr: [
      { value: 0, name: "Пройти до определенной даты" },
      /*   { value: 1, name: "Пройти за определеное количество дней" }, */
    ],
    courses: [
      {
        type: "course",
        name: "Имя наверное",
        timeLimit: 5,
        timeEnds: 6,
        progress: 55,
        testResult: 95,
      },
    ],
    tests: [],
    targetIds: [],
    coursesTests: [
      {
        type: "course",
        name: "Имя наверное",
        timeLimit: 5,
        timeEnds: 6,
        progress: 55,
        testResult: 95,
      },
    ],
    headersDialog: [
      { text: "", value: "checkBox", align: "flex-left", width: "fit-content" },
      { text: "Имя", value: "name", align: "flex-left", width: "fit-content" },
    ],
    headers: [
      {
        text: "Тип",
        value: "type",
        align: "flex-left",
        width: "fit-content",
      },
      {
        text: "Название",
        value: "name",
        align: "flex-left",
        width: "fit-content",
      },
      {
        text: "Ограничения",
        value: "timeLimit",
        align: "flex-left",
        width: "fit-content",
      },
      {
        text: "Остаток времени",
        value: "timeEnds",
        align: "flex-left",
        width: "fit-content",
      },
      {
        text: "Прохождение %",
        value: "progress",
        align: "flex-left",
        width: "fit-content",
      },
      {
        text: "Результат %",
        value: "testResult",
        align: "flex-left",
        width: "fit-content",
      },
    ],
  }),
  mounted() {},
  methods: {
    openDialog(string) {
      this.editString = string;
      this.dialogRadio = true;
    },
    sendAppointmentData() {},
    selectItems(item) {
      if (item.checkBox) {
        this.targetIds.push(item.id);
      } else {
        this.targetIds = this.targetIds.filter((v) => v != item.id);
      }
    },
    /*   async getCourses() {
      const arrConst = await this.$axios
        .get(this.$store.getters.apiAdminPanelV4 + "/course/list", {
          headers: {
            authorization: this.$store.getters.adminPanelHeaderAuth,
          },
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
      this.courses = arrConst.data;
    }, */
  },
};
</script>

<style lang="scss" scoped>
.block-buttons {
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.setting-block-statistics {
  display: flex;
  justify-content: space-between;

  .statistics-block {
    padding: 24px;
    background: var(--app-white);
    border-radius: 6px;
    margin: 0px 8px;
    width: 100%;
    // min-width: 385px;
    .statistics-block-title {
      font-size: 18px;
      font-weight: 600;
      color: var(--app-black);
      margin-bottom: 4px;
    }
    .statistics-block-value {
      font-size: 16px;
      color: var(--app-grey-3);
    }
  }
  .statistics-block:first-child {
    margin-left: 0px;
  }
  .statistics-block:last-child {
    margin-right: 0px;
  }
}
</style>