var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"32px 64px"}},[_c('div',{staticClass:"block-buttons"},[_c('v-btn',{staticClass:"button-white-blue",staticStyle:{"margin-right":"19px"},attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog('курс')}}},[_c('span',[_vm._v("Назначить курс")])]),_c('v-btn',{staticClass:"button-white-blue",staticStyle:{"margin-right":"19px"},attrs:{"depressed":""},on:{"click":function($event){return _vm.openDialog('экзамен')}}},[_c('span',[_vm._v("Назначить экзамен")])])],1),_vm._m(0),_c('div',{staticStyle:{"height":"calc(100vh - 285px)","margin-top":"40px"}},[_c('Table',{attrs:{"headers":_vm.headers,"items":_vm.coursesTests,"headerFixed":""}})],1),_c('Dialog',{ref:"dialogAppointment",attrs:{"titleText":("Назначение " + _vm.editString + "а группе"),"refForm":'formAppointment',"mainItems":[
      { slotName: 'radio' },
      {
        slotName: 'date',
      },
      { slotName: 'table' } ]},on:{"click:success":_vm.sendAppointmentData},scopedSlots:_vm._u([{key:"date",fn:function(){return [(_vm.radioValue == 0)?_c('DateInput',{attrs:{"label":"Дата","valid":"","hideDetails":true,"clearable":""},model:{value:(_vm.dateMs),callback:function ($$v) {_vm.dateMs=$$v},expression:"dateMs"}}):_vm._e(),(_vm.radioValue == 1)?_c('TextInput',{attrs:{"label":"Дней","valid":"","type":"number","hideDetails":true,"clearable":""}}):_vm._e()]},proxy:true},{key:"radio",fn:function(){return [_c('RadioButtons',{attrs:{"items":_vm.radioArr},model:{value:(_vm.radioValue),callback:function ($$v) {_vm.radioValue=$$v},expression:"radioValue"}})]},proxy:true},{key:"table",fn:function(){return [_c('div',{staticClass:"mt-8",staticStyle:{"height":"300px"}},[_c('Table',{attrs:{"items":_vm.courses,"headers":_vm.headersDialog,"headerHidden":""},scopedSlots:_vm._u([{key:"item.checkBox",fn:function(ref){
    var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.selectItems(item)}}},[_c('CheckBox',{attrs:{"color":"#0078FF"},model:{value:(item.checkBox),callback:function ($$v) {_vm.$set(item, "checkBox", $$v)},expression:"item.checkBox"}})],1)]}}],null,true)})],1)]},proxy:true},{key:"buttonDelete",fn:function(){return [_vm._v("Выбрано: "+_vm._s(_vm.targetIds.length))]},proxy:true}]),model:{value:(_vm.dialogRadio),callback:function ($$v) {_vm.dialogRadio=$$v},expression:"dialogRadio"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"setting-block-statistics"},[_c('div',{staticClass:"statistics-block"},[_c('div',{staticClass:"statistics-block-title"},[_vm._v("Всего курсов")]),_c('div',{staticClass:"statistics-block-value"})]),_c('div',{staticClass:"statistics-block"},[_c('div',{staticClass:"statistics-block-title"},[_vm._v("Всего экзаменов")]),_c('div',{staticClass:"statistics-block-value"})]),_c('div',{staticClass:"statistics-block"},[_c('div',{staticClass:"statistics-block-title"},[_vm._v("Прохождение")]),_c('div',{staticClass:"statistics-block-value"})]),_c('div',{staticClass:"statistics-block"},[_c('div',{staticClass:"statistics-block-title"},[_vm._v("Средний результат")]),_c('div',{staticClass:"statistics-block-value"})])])}]

export { render, staticRenderFns }